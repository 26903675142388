import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { IconArrowRight } from "../icons/Icons"

const HowItWorks = ({ className, heading = "How it works", boxClassName, arrowClassName }) => {
  const Step = ({ stepLink, stepImage, stepTitle, stepDescription }) => {
    return (
      <Link
        to={stepLink}
        className="hover:scale-[102%] duration-200 ease-in-out lg:max-w-max mx-auto grid grid-rows-[auto_1fr] @[56rem]:grid-rows-[auto_auto_1fr] @[56rem]:items-start grid-cols-1 lg:grid-cols-[auto_1fr] justify-center items-center text-left gap-x-4"
      >
        {stepImage}
        <h3 className="max-w-max row-span-1 col-span-1 mx-auto lg:mx-0 text-2xl py-2 @[56rem]:col-span-2">
          {stepTitle}
        </h3>
        <p className="mx-auto lg:mx-0 max-w-[450px] row-span-1 @[56rem]:col-span-2">
          {stepDescription}
        </p>
      </Link>
    )
  }
  return (
    <section className={"@container " + className || "w-full p-10 visibility-auto  "}>
      <h2 className={"py-10 text-center text-4xl uppercase"}>{heading}</h2>
      <div className={"@container flex flex-col gap-10 px-4 @[64rem]:flex-row"}>
        <Step
          stepLink="/what-are-you-looking-to-sell/"
          stepImage={
            <StaticImage
              className="w-full h-full min-h-[150px] row-span-2 col-span-1 @[56rem]:col-span-2 @[56rem]:max-h-[150px] @[56rem]:row-span-1"
              objectFit="contain"
              src={"../../images/homepage/value.png"}
              alt={"Valuation"}
            />
          }
          stepTitle={"1. Request a Quote"}
          stepDescription={
            "Find out how much is your watch or jewelry worth? The more information & photos you share with us the more accurate the market valuation will be. Upon completion of an online submission our experts will contact you with an initial quote."
          }
        />

        <Step
          stepLink="/ez-ship-box-request-form/"
          stepImage={
            <StaticImage
              className="w-full h-full min-h-[150px] row-span-2 col-span-1 @[56rem]:col-span-2 @[56rem]:max-h-[150px] @[56rem]:row-span-1"
              objectFit="contain"
              src={"../../images/homepage/shipping.png"}
              alt={"Shipping"}
            />
          }
          stepTitle={"2. Send Items for Inspection"}
          stepDescription={
            <>
              We will provide Easy Ship box and packing materials with our address label. Simply
              take it to the post office. Send it via <b>registered mail and fully insured*</b>, and
              we will refund your cost of shipping and insurance upon purchase.
              <br />
              <em className="block my-4">
                * Be sure to protect your valuables with adequate shipping insurance
              </em>
            </>
          }
        />

        <Step
          stepLink="/what-are-you-looking-to-sell/"
          stepImage={
            <StaticImage
              className="w-full h-full min-h-[150px] row-span-2 col-span-1 @[56rem]:col-span-2 @[56rem]:max-h-[150px] @[56rem]:row-span-1"
              objectFit="contain"
              src={"../../images/homepage/payment.png"}
              alt={"Payment"}
            />
          }
          stepTitle={"3. Receive Payment"}
          stepDescription={
            "Once the inspection is complete and details of the transaction are confirmed, you will quickly receive your payment via check or bank wire."
          }
        />
      </div>
    </section>
  )
}

export default HowItWorks
