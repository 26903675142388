import * as React from "react"
import {
  DupontRegistryLogo,
  WallStreetJournal,
  SunSentinel,
  ForbesLogo,
  RobbReport,
  SFBJLogo,
  MiamiHerald,
  ElNuevoHerald,
} from "../icons/Icons"

const AsAdvertisedOn = () => {
  return (
    <section>
      <p className="text-center font-bold text-2xl uppercase px-10">
        As seen and advertised in the last 42 years:
      </p>
      <div className="w-full p-10 grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-8 text-gray-400">
        <ForbesLogo />
        <WallStreetJournal />
        <RobbReport />
        <DupontRegistryLogo />
        <SunSentinel />
        <SFBJLogo />
        <MiamiHerald />
        <ElNuevoHerald />
      </div>
    </section>
  )
}

export default AsAdvertisedOn
